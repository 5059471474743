/* line 10, scss/70-modules/footertext/_module.scss */
.m-footer-text {
	font-size: 1em;
	line-height: 1.05;
	font-weight: 500;
	margin-bottom: 20px;
	color: rgb(var(--color-global-secondary));
	background: rgb(var(--color-global-secondary));
}

@media only screen and (max-width: 47.999em) {
	/* line 10, scss/70-modules/footertext/_module.scss */
	.m-footer-text {
		font-size: 1.165em;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 10, scss/70-modules/footertext/_module.scss */
	.m-footer-text {
		font-size: 1em;
	}
}

@media only screen and (min-width: 80em) {
	/* line 10, scss/70-modules/footertext/_module.scss */
	.m-footer-text {
		font-size: 1em;
	}
}

/* line 21, scss/70-modules/footertext/_module.scss */
.m-footer-text p {
	color: rgb(var(--color-global-primary));
}

/* line 26, scss/70-modules/footertext/_module.scss */
.footer-text__list {
	font-family: var(--font-family-text);
	font-display: swap;
}

/* line 31, scss/70-modules/footertext/_module.scss */
.footer-text__item {
	color: rgb(var(--color-global-secondary));
}

/* line 34, scss/70-modules/footertext/_module.scss */
.footer-text__item:after {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 100%;
	display: inline-block;
	margin: 0 5px;
	width: 5px;
	height: 5px;
	background-color: rgb(var(--color-global-secondary));
	content: "";
}

/* line 45, scss/70-modules/footertext/_module.scss */
.footer-text__item:last-child:after {
	display: none;
}

/* line 51, scss/70-modules/footertext/_module.scss */
.footer-text__link {
	color: rgb(var(--color-global-positive));
}

/* line 54, scss/70-modules/footertext/_module.scss */
.footer-text__link:hover {
	color: rgb(var(--color-dynamic-primary-color));
}

/* line 12, scss/80-themes/AffinityDefault/70-modules/footertext/module.scss */
.m-footer-text a {
	color: inherit;
}

/* line 16, scss/80-themes/AffinityDefault/70-modules/footertext/module.scss */
.m-footer-text .footer-links-container {
	background-color: #37395c;
}

/* line 20, scss/80-themes/AffinityDefault/70-modules/footertext/module.scss */
.m-footer-text .footer-item-link {
	font-size: 10.8;
	color: #92a2c1;
}

/* line 25, scss/80-themes/AffinityDefault/70-modules/footertext/module.scss */
.m-footer-text .footer-links-wrapper--single {
	display: none;
}

/* line 28, scss/80-themes/AffinityDefault/70-modules/footertext/module.scss */
.m-footer-text .footer-links-wrapper--single:first-child {
	width: 100%;
	display: block;
}

/* line 35, scss/80-themes/AffinityDefault/70-modules/footertext/module.scss */
.footer-text__separator {
	border-top: 20px solid rgb(var(--color-global-positive));
}

/*# sourceMappingURL=../../../../../true */